<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Produkte</h4>
                            <p>Liste aller Produkte</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="mr-1 btn btn-default btn-xs" @click="loadProducts">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-xs" @click="createModal" v-if="$auth.check('products.create')">
                                <i class="fas fa-plus-circle"></i> Neues Produkt
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <template v-if="products.data.length > 0">
                            <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th><input type="checkbox" v-model="selectAll" /></th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('name')">Name</a>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th style="width: 140px;">Aktionen</th>                 
                                </thead>
                                <tbody>
                                    <tr v-for="(product) in products.data" :key="product.id">
                                        <td><input type="checkbox" :value="product.id" v-model="selectedProducts"/></td>
                                        <td>{{ product.name }}</td>
                                        <td>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'products.show', params: {id: product.id}}" v-if="$auth.check('products.show') && !$auth.check('products.edit')"><i class="fas fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'products.details', params: {id: product.id}}" v-if="$auth.check('products.edit')"><i class="fas fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteProduct(product.id)" variant="danger" v-if="$auth.check('products.destroy')"><i class="fas fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </template>

                        <template v-else>
                            <h5>Keine Produkte gefunden</h5>
                        </template>
                    </div>

                    <div class="clearfix align-middle card-footer">
                        <template v-if="products.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="products" @pagination-change-page="loadProducts" :limit="3"></pagination>
                                </div>
                                <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ products.meta.from }} - {{ products.meta.to }} von {{ products.meta.total }}</span>
                                </div>
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Artikel</option>
                                        <option value="50">50 Artikel</option>
                                        <option value="75">75 Artikel</option>
                                        <option value="100">100 Artikel</option>
                                        <option value="125">125 Artikel</option>
                                        <option value="150">150 Artikel</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>
                </div>
            </div>
        </div>


        <b-modal id="productModal" title="Erstelle ein neues Produkt" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
            <form ref="productForm" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">

                        <div class="form-group">
                            <label for="name" class="control-label">Name*</label>
                            <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                            <has-error :form="form" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="notice" class="control-label">Notizen</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="notice"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="recipe" class="control-label">Herstellung</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.recipe" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="recipe"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="logo" class="control-label">Produktbild</label>
                            <b-form-file size="sm" id="logo"  class="form-control form-control-sm" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                            <has-error :form="form" field="logo"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>


  </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import {mapState} from 'vuex';


export default {
    name: "Products",
    title: "Produkte",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                notice: "",
                recipe: "",
                logo: [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: true,
            products: {
                data: [],
            },
            selectedProducts: [],
        }
    },

    watch: {
        params: {
            handler () {
                this.loadProducts();
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.products.data ? this.selectedProducts.length == this.products.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.products.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedProducts = selected;
            }
        },
        ...mapState('products', ['params', 'search', 'page'])
    },


    methods: {
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        onRowDoubleClick(params) {
            this.$router.push({name: 'products.details', params: {id: params.row.id}})
        }, 

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createProduct();     
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("productModal");
        },

        createProduct(){
            this.form
                .post("/products")
                .then(() => {
                    this.loadProducts();
                    this.$bvModal.hide("productModal");
                    this.$swal({
                        icon: "success",
                        title: "Produkt gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        deleteProduct(id){
          this.$swal({
                    title: "Möchtest du das Produkt wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/products/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Produkt erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.loadProducts();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        loadProducts(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/products", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.products = response.data;
                    this.$store.commit('products/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadProducts();
    },
}
</script>

<style>

</style>